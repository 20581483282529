export enum ThreadStatus
{
    draft,
    active,
    pending,
    cancelled,
    pending_cancelled,
    archived,
    hidden,
    deleted
}