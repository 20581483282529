import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IIndexRecord } from '../../@types/recipients';
import { MessagingState } from '../../context/Context';
import { ActionType } from '../../context/ActionTypes';
import TabItem from './TabItem';
import { RecipientSelectionTypes } from './RecipientsPersonList';
import { LoadingState } from '../../@types/loadingstates';
import { Icon, Spinner } from '@vismaux/react-vud';
import RecipientService from '../../services/RecipientService';

interface Tab {
    id: string;
    title: string;
}

const RecipientTabs = () => {
    const { dispatch, state: { generalRecipientData } } = MessagingState();
    const { t } = useTranslation();

    const initialTabs: Tab[] = [
        { id: '0', title: t('selectRecipients.general') }
    ];

    const tabs: Tab[] = generalRecipientData?.indexRecords.map((record: IIndexRecord) => {
        return {
            id: record.id,
            title: record.caption
        };
    }) || [];

    const combinedTabs: Tab[] = [...initialTabs, ...tabs];

    const [activeTab, setActiveTab] = useState(combinedTabs[0].id);

    const onClickTab = async (tabId: string) => {

        setActiveTab(tabId);
        dispatch({
            type: ActionType.SET_SELECTED_SCHOOL,
            payload: tabId
        });
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: ''
        });
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.None,
                records: []
            }
        });
        if (tabId === '0') {
            dispatch({
                type: ActionType.SET_PRIMUS_SEARCHES,
                payload: {
                    studentRecords: [],
                    teacherRecords: [],
                    personnelRecords: [],
                    workplaceInstructorRecords: [],
                    trainingCoordinatorRecords: []
                }
            });
        } else {
            try {
                const primusSearchesResponse = await RecipientService.getPrimusSearches(tabId);
                if (RecipientService.primusSearchLoadingState !== LoadingState.Loading) {
                    dispatch({
                        type: ActionType.SET_PRIMUS_SEARCHES,
                        payload: {
                            studentRecords: primusSearchesResponse.studentRecords,
                            teacherRecords: primusSearchesResponse.teacherRecords,
                            personnelRecords: primusSearchesResponse.personnelRecords,
                            workplaceInstructorRecords: primusSearchesResponse.workplaceInstructorRecords,
                            trainingCoordinatorRecords: primusSearchesResponse.trainingCoordinatorRecords
                        }
                    });
                }
            } catch {
                dispatch({
                    type: ActionType.SET_PRIMUS_SEARCHES,
                    payload: {
                        studentRecords: [],
                        teacherRecords: [],
                        personnelRecords: [],
                        workplaceInstructorRecords: [],
                        trainingCoordinatorRecords: []
                    }
                });
            }

        }
    };

    return (
        <div className="horizontal-tabs">
            {combinedTabs.map((tab, index) => (
                <TabItem
                    key={tab.id}
                    tabIndex={index + 1}
                    onClick={() => onClickTab(tab.id)}
                    isActive={tab.id === activeTab}>
                    {tab.title}
                </TabItem>
            ))}
            {generalRecipientData.loadingState === LoadingState.Loading && (
                <TabItem
                    key={'generalRecipientData-loading-state-tab'}
                    tabIndex={-1}
                    onClick={() => { }}
                    isActive={false}>
                    <Spinner size='sm' />
                </TabItem>
            )}
            {generalRecipientData.loadingState === LoadingState.Error && (
                <TabItem
                    key={'generalRecipientData-loading-error-tab'}
                    tabIndex={-1}
                    onClick={() => { }}
                    isActive={false}>
                    <Icon
                        name='error'
                        size='sm'
                    />
                    <span className='error-text'>{t('errors.generalRecipientSchoolDataFetchFailed')}</span>
                </TabItem>
            )}
        </div>
    );
};

export default RecipientTabs;