import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionType } from '../../context/ActionTypes';
import { IContextPaginationData, MessagingState } from '../../context/Context';
import { useToast } from '../../context/ToastContext';
import DiscussionThreadService from '../../services/DiscussionThreadService';
import { IDiscussionThread } from '../../@types/discussionthread';
import * as amplitude from '@amplitude/analytics-browser';

export const useCancelThreadHandler = () => {
    const navigate = useNavigate();
    const { dispatch } = MessagingState();
    const { folderParam, pageNumber} = useParams();
    const { createToast } = useToast();

    const cancelThreadHandler = async(
        discussionThreadId: number, 
        discussionThreads: IDiscussionThread[],
        paginationData: IContextPaginationData,
        amplitudeProps: {guid_id: string},
    ) => {
        amplitude.track('MessageCancel', {guid_id: amplitudeProps.guid_id});
    
        try {
            const cancelResponse = await DiscussionThreadService.cancelThread(discussionThreadId);
    
            // check that promise successful
            if (cancelResponse) {
                // Update discussion threads list if the thread was in the current view
                if (discussionThreads.some(thread => thread.id === cancelResponse.data.id)) {
                    let response = null;
                    switch (folderParam) {
                        case 'received':
                            response = await DiscussionThreadService.getReceived({ pageNumber: paginationData.currentPage, pageSize: 10 });
                            break;
                        case 'sent':
                            response = await DiscussionThreadService.getSent({ pageNumber: paginationData.currentPage, pageSize: 10 });
                            break;
                    }
    
                    if (response) {
                        dispatch({
                            type: ActionType.SET_DISCUSSIONTHREADS,
                            payload: response.discussionThreads
                        });
                        dispatch({
                            type: ActionType.SET_PAGINATION_DATA,
                            payload: { currentPage: response.paginationData.currentPage, totalPages: response.paginationData.totalPages }
                        });
                    }
                }
                navigate(`/${folderParam}/page/${pageNumber}/messagecanceled`);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const errorResponse = error.response?.data?.detail as string;
            const titleMessage = errorResponse.startsWith('cancel_') ? t(`errors.cancelErrors.${errorResponse.split(',')[0]}`) : t('errors.cancelErrors.cancel_Error');
            createToast({
                title: titleMessage,
                toastType: 'danger'
            });
        }
    };

    return { cancelThreadHandler };
};