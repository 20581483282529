import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@vismaux/react-vud';
import { Guid } from 'typescript-guid';
import { IDiscussionThread } from '../@types/discussionthread';
import classNames from 'classnames';
import { MessagingState } from '../context/Context';
import useModal from './hooks/useModal';
import { useTranslation } from 'react-i18next';
import CancelModal from './Modals/CancelModal';
import useDetectOutsideClick from './hooks/useDetectOutsideClick.';
import { useCancelThreadHandler } from './hooks/useCancelThreadHandler';

interface IDiscussionThreadMenuProps {
    activeDiscussionThread: IDiscussionThread;
}

const DiscussionThreadMenu: React.FC<IDiscussionThreadMenuProps> = ({activeDiscussionThread}) => {

    const { state: {discussionThreads, paginationData } } = MessagingState();
    const modal = useModal();
    const {t} = useTranslation();
    const defaultAmplitudeProps = {
        guid_id: Guid.create().toString()
    };
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [amplitudeProps] = useState(defaultAmplitudeProps);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const handleOpenMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    useDetectOutsideClick(wrapperRef, () => setIsMenuOpen(false));
    const { cancelThreadHandler } = useCancelThreadHandler();
    
    //when activeDiscussionThread changes close menu
    useEffect(() => {
        setIsMenuOpen(false);
    },[activeDiscussionThread]);

    return (
        <>
            <div
                className={classNames('col-md-1 dropdown justify-content-end align-self-start', isMenuOpen ? 'open' : '' )}
                ref={wrapperRef}>
                <button
                    type="button"
                    className="btn btn-icon btn-transparent dropdown"
                    data-toggle="dropdown"
                    aria-label={t('buttons.discussionThreadMenu')} 
                    aria-expanded={isMenuOpen} 
                    onClick={handleOpenMenu}
                    id="discussionThreadDropDownMenu">                           
                    <Icon
                        name='other-circle'
                        size='lg'
                    />
                </button>
                <ul 
                    id="discussionThreadDropDownMenu"
                    className="dropdown-menu" 
                    role="menu" 
                    aria-expanded={isMenuOpen} 
                    aria-hidden={!isMenuOpen} 
                    aria-labelledby="discussionThreadDropDownMenu">
                    <li>
                        <button
                            className={classNames('btn btn-transparent menu-item', !activeDiscussionThread.canCancelThread ? 'disabled' : '')}
                            onClick={() => modal.toggle()}
                            aria-disabled={!activeDiscussionThread.canCancelThread}
                        >
                            <Icon
                                name='rotate-ccw'
                                size='lg'
                                className='rotate-ccw'
                                dynamic={false}
                            />
                            <div>{t('buttons.cancelMessage')}</div>
                        </button>
                    </li>
                    <li className="divider"></li>
                </ul>
            </div>
            <CancelModal
                isShowing={modal.isOpen}
                toggle={modal.toggle}
                canCancel={activeDiscussionThread.canCancelThread}
                title={t('prompts.confirm.undoMessage')}
                bodyContent={<div />}
                handleConfirm={() => cancelThreadHandler(activeDiscussionThread.id, discussionThreads, paginationData, amplitudeProps)}
            />
        </>
    );
};

export default DiscussionThreadMenu;