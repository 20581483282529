import { Icon } from '@vismaux/react-vud';
import React, { useEffect } from 'react';
import { IFolder } from '../@types/folder';
import FolderService, { FolderSelection } from '../services/FolderService';
import FolderListItem from './FolderListItem';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as amplitude from '@amplitude/analytics-browser';
import { useParams } from 'react-router-dom';
import WilmaNavLink from './WilmaReactRouter/WilmaNavLink';
import { useUpdateInboxUnreadCount } from './hooks/useUpdateInboxUnreadCount';
import { useToast } from '../context/ToastContext';
import { MessagingState, initialState } from '../context/Context';
import { ActionType } from '../context/ActionTypes';
import { LoadingState } from '../@types/loadingstates';

const FolderList = () => {
    const {t} = useTranslation();
    const { folderParam, pageNumber } = useParams();
    const folders = FolderService.getAll();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();
    const { createToast } = useToast();
    const { dispatch } = MessagingState();

    useEffect(() => {
        amplitude.track('folder_selected', {
            folder_name : folderParam
        });

        if (folderParam === 'received') {
            updateInboxUnreadCount();
        }
    }, [folderParam, updateInboxUnreadCount]);

    return (
        <nav 
            className="list-group full-height folder-list"
            title={t('titles.folderSelection')}>
            <WilmaNavLink
                className="btn btn-primary bg-secondary action-button"
                id="new-message-button"
                to={`/${folderParam}/page/${pageNumber}/compose`}
                relative='path'
                tabIndex={0}>
                <Icon
                    name='edit'
                    dynamic
                    size='sm' /><div className='folder-list-action-text'>{t('newMessage')}</div>
            </WilmaNavLink>
            {
                folders
                    .filter(folder => !folder.hideInFolderList)
                    .map((folder: IFolder) => {
                        //TODO: Remove when all folders are implemented
                        const isDisabled = folder.folderSelection === FolderSelection.Disabled;
                        const selectedFolder = folderParam === folder.path;
                        const folderListItemWrapperClass = classNames('folder-list-group-item', {
                            active: selectedFolder,
                            disabled: isDisabled
                        });
                        return (
                            !isDisabled ? (
                                <WilmaNavLink
                                    key={folder.id}
                                    className={folderListItemWrapperClass}
                                    aria-current={selectedFolder}
                                    to={`/${folder.path}/page/1`}
                                    disableLink={selectedFolder}
                                    onClick={() => {
                                        dispatch({
                                            type: ActionType.SET_SEARCH_PAYLOAD,
                                            payload: initialState.searchPayload
                                        });
                                        dispatch({
                                            type: ActionType.SET_SEARCH_FOCUS,
                                            payload: false
                                        });
                                        dispatch({
                                            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                                            payload: { thread: null, loadingState: LoadingState.Done }
                                        });
                                    }}
                                    relative='path'
                                    tabIndex={0}
                                ><FolderListItem
                                        folder={folder}
                                        showIcons={true}
                                        showUnread={folder.folderSelection === FolderSelection.Received}/>
                                </WilmaNavLink>
                            ) :
                                (
                                    <li
                                        className={folderListItemWrapperClass}
                                        key={folder.id}
                                        onClick={() => createToast({
                                            title: t('errors.notImplemented'),
                                            toastType: 'danger'
                                        })}
                                    >
                                        <FolderListItem
                                            folder={folder}
                                            showIcons={true}
                                            showUnread={false}
                                        />
                                    </li>
                                )
                        );
                    })
            }
        </nav>
    );
};

export default FolderList;
