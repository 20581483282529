import { useTranslation } from 'react-i18next';
import { IPagedResponsePayload } from '../../@types/discussionthread';
import { IFolder } from '../../@types/folder';
import { ActionType } from '../../context/ActionTypes';
import { MessagingState } from '../../context/Context';
import { useToast } from '../../context/ToastContext';
import DiscussionThreadService from '../../services/DiscussionThreadService';
import FolderService, { FolderSelection } from '../../services/FolderService';
import { LoadingState } from '../../@types/loadingstates';
import { useNavigate, useParams } from 'react-router-dom';

export const useDiscussionThreads = () => {
    const { dispatch } = MessagingState();
    const { createToast } = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pageNumber} = useParams();

    const getDiscussionThreads = async (folder: IFolder) => {
        const folders: IFolder[] = FolderService.getAll();

        let response : IPagedResponsePayload = {discussionThreads: [], paginationData: {
            totalPages: 1,
            currentPage: 0,
            pageSize: 0,
            totalCount: 0
        }};

        let folderSelection = folder;

        dispatch({
            type: ActionType.SET_PAGINATION_DATA,
            payload: { currentPage: 1, totalPages: 1}
        });
        dispatch({
            type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
            payload: LoadingState.Loading
        });

        try {
            switch (folder.folderSelection) {
                case FolderSelection.Received:
                    response = (await DiscussionThreadService.getReceived());
                    folderSelection = folders[FolderSelection.All];
                    navigate(`/received/page/${pageNumber}`);
                    break;
    
                case FolderSelection.Sent:
                    response = (await DiscussionThreadService.getSent());
                    folderSelection = folder;
                    navigate(`/sent/page/${pageNumber}`);
                    break;
                
                default:
                    response = (await DiscussionThreadService.getReceived());
                    folderSelection = folders[FolderSelection.All];
                    navigate(`/received/page/${pageNumber}`);
                    break;
            }

            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS,
                payload: response.discussionThreads
            });
            dispatch({
                type: ActionType.SET_PAGINATION_DATA,
                payload: { currentPage: response.paginationData.currentPage, totalPages: response.paginationData.totalPages }
            });
            dispatch({
                type: ActionType.SET_SEARCH_PAYLOAD,
                payload: { searchText: '', folder: folderSelection }
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Done
            });
        } catch {
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Error
            });
            createToast({
                title: t('errors.messageLoadingError'),
                toastType: 'danger'
            });
        }
        return response.discussionThreads;
    };

    return { getDiscussionThreads };
};