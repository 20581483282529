import React, { createContext, useContext, useReducer } from 'react';
import { IDiscussionThread, IDiscussionThreadSelectPayload } from '../@types/discussionthread';
import { IFolder } from '../@types/folder';
import { IMessage } from '../@types/message';
import { ISearchResult } from '../@types/search';
import { IUser } from '../@types/user';
import { IIndexRecords, IModalRecipient, IPrimusSearches, IRecipientSelectionData } from '../@types/recipients';
import FolderService, { FolderSelection } from '../services/FolderService';
import { MessagingContextActions, messagingReducer } from './Reducers';
import { StringOption } from '../components/WilmaAsyncSelect/WilmaAsyncSelect';
import { RecipientSelectionTypes } from '../components/SelectRecipients/RecipientsPersonList';
import { Loading, LoadingState } from '../@types/loadingstates';
import dayjs from 'dayjs';

export interface IMessagingContextState {
    folders: IFolder[];
    discussionThreads: IDiscussionThread[];
    unreadInboxCount: number;
    unreadInboxCountLoading: LoadingState;
    searchResponses: ISearchResult[];
    activeDiscussionThread: IDiscussionThread | null;
    threadLoadingState: LoadingState;
    errorCanceled: boolean;
    unreadMessages: IMessage[];
    currentUser: IUser | undefined;
    lastActivityTimestamp: dayjs.Dayjs;
    lastRefreshTime: dayjs.Dayjs;
    lastSentMessageData: {
        threadId: number | null;
        canCancelThread: boolean;
    }
    searchFocus: boolean;
    searchPayload: {
        searchText: string,
        folder: IFolder,
    };
    selectedRecipients: { options: StringOption[] };
    modalRecipients: { modalRecipientOptions: IModalRecipient[] };
    message: IDiscussionThreadSelectPayload;
    recipientListData: IRecipientSelectionData;
    generalRecipientData: IIndexRecords & Loading;
    primusSearches: IPrimusSearches;
    selectedSchoolId: string;
    activeButtonId: string;
    modalHiddenRecipientLabels: string[];
    paginationData: IContextPaginationData;
    discussionThreadsLoading: LoadingState;
}

export interface IContextPaginationData {
    currentPage: number;
    totalPages: number;
}

interface ProviderProps {
    children: React.ReactNode,
    preloadedState?: Partial<IMessagingContextState>;
}

export const initialState: IMessagingContextState = {
    folders: [],
    discussionThreads: [],
    unreadInboxCount: 0,
    unreadInboxCountLoading: LoadingState.Loading,
    searchResponses: [],
    activeDiscussionThread: null,
    threadLoadingState: LoadingState.Done,
    errorCanceled: false,
    unreadMessages: [],
    currentUser: undefined,
    lastActivityTimestamp: dayjs(),
    lastRefreshTime: dayjs(),
    lastSentMessageData: {
        threadId: null,
        canCancelThread: false
    },
    searchFocus: false,
    searchPayload: {
        searchText: '',
        folder: FolderService.getAll()[FolderSelection.All],
    },
    selectedRecipients: { options: [] },
    modalRecipients: { modalRecipientOptions: [] },
    message: {
        title: '',
        recipients: [],
        message: '',
        seeNames: false,
        seeResponses: false
    },
    recipientListData: {
        showRecipientList: RecipientSelectionTypes.None,
        records: [],
    },
    generalRecipientData: {
        indexRecords: [],
        loadingState: LoadingState.Loading,
    },
    primusSearches: {
        studentRecords: [],
        teacherRecords: [],
        personnelRecords: [],
        workplaceInstructorRecords: [],
        trainingCoordinatorRecords: []
    },
    selectedSchoolId: '0',
    activeButtonId: '',
    modalHiddenRecipientLabels: [],
    paginationData: { currentPage: 1, totalPages: 1 },
    discussionThreadsLoading: LoadingState.Loading
};

const MessagingContext = createContext<{
    state: IMessagingContextState;
    dispatch: React.Dispatch<MessagingContextActions>;
}>({
    state: initialState,
    dispatch: () => null
});

const MessagingProvider = (props: ProviderProps) => {
    const [state, dispatch] = useReducer(messagingReducer,
        {
            ...initialState,
            ...props.preloadedState
        }
    );

    return (
        <MessagingContext.Provider value={ { state, dispatch } } >
            {props.children}
        </ MessagingContext.Provider>
    );
};

export default MessagingProvider;

export const MessagingState = () => {
    return useContext(MessagingContext);
};
