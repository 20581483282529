import { Icon, Spinner } from '@vismaux/react-vud';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDiscussionThread, IDiscussionThreadReplyPayload } from '../@types/discussionthread';
import { ActionType } from '../context/ActionTypes';
import { MessagingState} from '../context/Context';
import { useToast } from '../context/ToastContext';
import DiscussionThreadService from '../services/DiscussionThreadService';
import { getUserDisplayName } from '../utils/utils';
import { useDiscussionThreads } from './hooks/useDiscussionThreads';
import FolderService, { FolderSelection } from '../services/FolderService';
import { LoadingState } from '../@types/loadingstates';

const DiscussionThreadReply = () => {
    const { t } = useTranslation();
    const [isReplyBoxVisible, setIsReplyBoxVisible] = useState(false);
    const [isReplyToAuthor, setIsReplyToAuthor] = useState(false);
    const [isReplyDisabled, setIsReplyDisabled] = useState(false);
    const [replyMessageId, setReplyMessageId] = useState<number>();
    const toast = useToast();
    const { state: { activeDiscussionThread }, dispatch} = MessagingState();
    // Author is part of recipients, so therefore minimum recipient count currently is 2
    const hasSingleRecipient  = (activeDiscussionThread?.recipients && activeDiscussionThread.recipients.length <= 2);
    const { getDiscussionThreads } = useDiscussionThreads();
    const [ loadingState, setLoadingState ] = useState<LoadingState>(LoadingState.Done);

    useEffect(() => {
        setIsReplyBoxVisible(false);
        setIsReplyToAuthor(false);
    }, [activeDiscussionThread]);

    const [reply, setReply] = useState('');

    const sendReply = async () => {
        const discussionThreadNotActive = !activeDiscussionThread || !activeDiscussionThread.id;
        if(isReplyDisabled || discussionThreadNotActive) 
            return;
        setIsReplyDisabled(true);
        try{
            setLoadingState(LoadingState.Loading);
            if (!isReplyToAuthor) {
                const newThread = await DiscussionThreadService.reply(reply, activeDiscussionThread.id);
                setLoadingState(LoadingState.Done);
                setActiveDiscussionThread(newThread.data);
            } else {
                if (replyMessageId == null) {
                    throw new Error('Should set message id to which replying');
                }
                const discussionReplyThread: IDiscussionThreadReplyPayload = {
                    replyMessage: reply,
                    originalThreadId: activeDiscussionThread.id,
                    messageIdToReplyTo: replyMessageId
                };
                const newThread = await DiscussionThreadService.createReplyToSender(discussionReplyThread);
                await moveToRefreshedReceivedFolder();
                setLoadingState(LoadingState.Done);
                setActiveDiscussionThread(newThread.data);
            }
        } catch (error) {
            toast.createToast({
                title: t('errors.messageSendingError'),
                toastType: 'danger'
            });
            setLoadingState(LoadingState.Error);
        }
        setIsReplyDisabled(false);
    };

    const cancelHandler = () => {
        setIsReplyBoxVisible(false);
        setIsReplyToAuthor(false);
    };

    const moveToRefreshedReceivedFolder = async () => {
        const receivedFolder = FolderService.getAll()[FolderSelection.Received];
        receivedFolder && await getDiscussionThreads(receivedFolder);
    };

    const setActiveDiscussionThread = (discussionThread: IDiscussionThread) => {
        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: { thread: discussionThread, loadingState: LoadingState.Done } 
        });
        setIsReplyBoxVisible(false);
        setReply('');
    };

    const replyTo = (messageId: number) => {
        if(activeDiscussionThread?.author == undefined) {
            return;
        }
        try {
            setIsReplyToAuthor(true);
            setIsReplyBoxVisible(!isReplyBoxVisible);
            setReplyMessageId(messageId);
        } catch (error) {
            toast.createToast({
                title: t('errors.messageSendingError'),
                toastType: 'danger'
            });
        }
    };

    const replyToSender = () => {
        activeDiscussionThread && replyTo(activeDiscussionThread.messages[0].id);
    };

    const replyToAllAuthorMessages = () => {
        activeDiscussionThread && replyTo(activeDiscussionThread.messages.slice(-1)[0].id);
    };

    const replyToAll = () => {
        setIsReplyBoxVisible(!isReplyBoxVisible);
    };

    const onChange = ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const reply = e.target.value;
        setReply(reply);
    });

    return (
        <>
            {isReplyBoxVisible ? (
                <>
                    {isReplyToAuthor  && (
                        <div className='col-md-12'>
                            <span id='discussion-recipient-text-row'>{t('to') + getUserDisplayName(activeDiscussionThread?.author)}
                            </span>
                        </div>
                    )}
                    <div className="col-md-12 h-screen-20">
                        <textarea
                            autoFocus
                            className='h-100 resize-none'
                            name='message'
                            onChange={onChange}
                            placeholder={t('placeholders.message')} />
                    </div>
                    <div className="row float-right no-gutters">
                        <button
                            type="button"
                            disabled={loadingState === LoadingState.Loading}
                            onClick={() => cancelHandler()}
                            className="btn bg-transparent action-button">
                            <span className="close"/>
                            {t('cancel')}
                        </button>
                        <button
                            type="button"
                            onClick={sendReply}
                            disabled={reply.length===0 || isReplyDisabled || loadingState === LoadingState.Loading}
                            className='btn btn-primary bg-secondary action-button'>
                            <Icon
                                name='paperplane'
                                size='sm' />
                            {t('submitMessage')}
                            {loadingState === LoadingState.Loading && <Spinner size='sm'/>}
                        </button>
                    </div>
                </>
            ) : (
                <div className="row float-right no-gutters">
                    {
                    // TODO: implemented later as part of discarding messages
                    /*<button
                        type="button"
                        className='btn bg-transparent action-button'>
                        <Icon
                            name="delete"
                            dynamic
                            size="sm" />
                        {t('moveToTrash')}
                    </button>*/}
                    {activeDiscussionThread?.canReplyToSender && (
                        <button
                            type="button"
                            onClick={replyToSender}
                            className='btn btn-primary bg-secondary action-button'>
                            <Icon
                                name='send'
                                dynamic
                                size='sm' />
                            {t('replyToSender')}
                        </button>
                    )}
                    {activeDiscussionThread?.canReplyToAll && (
                        <button
                            type="button"
                            onClick={replyToAll}
                            className='btn btn-primary bg-secondary action-button'>
                            <Icon
                                name='send'
                                dynamic
                                size='sm' />
                            {hasSingleRecipient ? t('reply'): t('replyToAll')}
                        </button>
                    )}
                    {activeDiscussionThread?.canReplyToAllAuthorMessages && (
                        <button
                            type="button"
                            onClick={replyToAllAuthorMessages}
                            className='btn btn-primary bg-secondary action-button'>
                            <Icon
                                name='send'
                                dynamic
                                size='sm' />
                            {t('replyToSender')}
                        </button>
                    )}
                </div>
            )}
        </>
    );
};

export default DiscussionThreadReply;
