import { Icon } from '@vismaux/react-vud';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MessagingState } from '../../context/Context';
import { Guid } from 'typescript-guid';
import useModal from '../hooks/useModal';
import CancelModal from '../Modals/CancelModal';
import { useCancelThreadHandler } from '../hooks/useCancelThreadHandler';

const MessageSent = () => {
    const {t} = useTranslation();
    const { state: { lastSentMessageData, paginationData, discussionThreads } } = MessagingState();
    const modal = useModal();
    const { cancelThreadHandler } = useCancelThreadHandler();

    const defaultAmplitudeProps = {
        guid_id: Guid.create().toString()
    };
    const [amplitudeProps] = useState(defaultAmplitudeProps);
    const [allowCancelButton, setAllowCancelButton] = useState(lastSentMessageData.canCancelThread);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAllowCancelButton(false);
        }, 5000);

        return () => clearTimeout(timer); // This will clear the timer if the component is unmounted before the timer ends
    }, []);

    return(
        <>
            <div className='sent-message-container'>
                <Icon
                    name='paperplane'
                    dynamic
                    size='lg' 
                    // className='sent-message-icon'
                    className='paperplane-icon'
                />
                <p className='sent-message-text'>{t('messageSent')}</p>
                <button 
                    className={classNames('btn btn-primary bg-secondary action-button', !allowCancelButton ? 'disabled' : '')}
                    onClick={() => modal.toggle()}
                    disabled={!allowCancelButton}>
                    {t('buttons.undo')}
                </button>
            </div>
            <CancelModal
                isShowing={modal.isOpen}
                toggle={modal.toggle}
                title={t('prompts.confirm.undoMessage')}
                canCancel={lastSentMessageData.canCancelThread}
                bodyContent={<div />}
                handleConfirm={() => lastSentMessageData.threadId && cancelThreadHandler(lastSentMessageData.threadId, discussionThreads, paginationData, amplitudeProps)}
            />
        </>
    );
};

export default MessageSent;