
import { messagingHttpClient } from '../http-common';
import { IDiscussionThread, IDiscussionThreadCreatePayload, IDiscussionThreadReplyPayload, IPagedRequestPayload, IPagedResponsePayload, IDiscussionThreadResponse, IReceivedUnreadCount, IDiscussionThreadUpdatePayload } from '../@types/discussionthread';
import { CanceledError } from 'axios';
import { ISearchPayload, ISearchResponse } from '../@types/search';
import { ThreadStatus } from '../@types/threadStatus';

class DiscussionThreadService {

    private abortController = new AbortController();
    private discussionThreadAbortController = new AbortController();

    getReceived = async (payload? : IPagedRequestPayload): Promise<IPagedResponsePayload> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const queryString = payload ? `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}` : '';
            const response = await messagingHttpClient.get<IPagedResponsePayload>('/ms/messaging/discussionthread/received' + queryString, { signal: this.abortController.signal });
            const modifiedData = response.data?.discussionThreads.map(discussionThread =>
            // Convert backend sentAt strings to JS dates for processing
                ({
                    ...discussionThread, messages: discussionThread.messages.map(message => ({ ...message, sentAt: new Date(message.sentAt) }))
                })
            );
            return {
                discussionThreads: modifiedData,
                paginationData: response.data?.paginationData
            };

        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        totalCount: 0,
                        pageSize: 0,
                        currentPage: 0,
                        totalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    InboxUnreadCount() {
        return messagingHttpClient.get<IReceivedUnreadCount>('/ms/messaging/discussionthread/unread');
    }

    create(data: IDiscussionThreadCreatePayload) {
        return messagingHttpClient.post<IDiscussionThreadResponse>('/ms/messaging/discussionthread', data);
    }

    createReplyToSender(data: IDiscussionThreadReplyPayload) {
        return messagingHttpClient.post<IDiscussionThread>('/ms/messaging/discussionthread/replytosender', data);
    }

    reply(data: string, id: number) {
        return messagingHttpClient.post<IDiscussionThread>('/ms/messaging/discussionthread/' + id, { reply: data });
    }

    getSent = async (payload? : IPagedRequestPayload): Promise<IPagedResponsePayload> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const queryString = payload ? `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}` : '';
            const response = await messagingHttpClient.get<IPagedResponsePayload>('/ms/messaging/discussionthread/sent' + queryString, { signal: this.abortController.signal });
            const dateConvertedData = response.data?.discussionThreads.map(discussionThread =>
                // Convert backend sentAt strings to JS dates for processing
                ({
                    ...discussionThread, messages: discussionThread.messages.map(message => ({ ...message, sentAt: new Date(message.sentAt) }))
                })
            );
            
            return {
                discussionThreads: dateConvertedData,
                paginationData: response.data?.paginationData
            };

        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        totalCount: 0,
                        pageSize: 0,
                        currentPage: 0,
                        totalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    search = async (searchPayload: ISearchPayload) : Promise<ISearchResponse> => {
        this.abortController.abort();
        this.abortController = new AbortController();
        try {
            const response =  await messagingHttpClient.post<ISearchResponse>('/ms/messaging/Search', {...searchPayload }, {signal: this.abortController.signal});
            const data = response.data;

            const results = {
                discussionThreads: data.discussionThreads,
                paginationData: data.paginationData
            };

            return results;
        } catch (e) {
            if (e instanceof CanceledError) {
                return {
                    discussionThreads: [],
                    paginationData: {
                        totalCount: 0,
                        pageSize: 0,
                        currentPage: 0,
                        totalPages: 0
                    }
                };
            } else {
                throw e;
            }
        }
    };

    updateRecipientList = async (recipientGuids: string[], threadId: number) => {
        return await this.updateThread({ recipientGUIDs: recipientGuids }, threadId);
    };

    updateThread = async (payload: IDiscussionThreadUpdatePayload, threadId: number) => {
        return messagingHttpClient.patch<IDiscussionThread>(`/ms/messaging/discussionthread/${threadId}`, payload);
    };

    cancelThread = async (threadId: number) => {
        return await this.updateThread({ threadStatus: ThreadStatus.cancelled }, threadId);
    };

    getDiscussionThread = async (threadId: number) => {
        this.discussionThreadAbortController.abort();
        this.discussionThreadAbortController = new AbortController();
        try {
            const response = await messagingHttpClient.get<IDiscussionThread>(`/ms/messaging/discussionthread/${threadId}`, { signal: this.discussionThreadAbortController.signal });
            return response.data;
        } catch (e) {
            if (e instanceof CanceledError) {
                return null;
            } else {
                throw e;
            }
        }
    };

    setMessageAsRead = (threadId: number, messageId: number) => {
        return messagingHttpClient.post<void>(`/ms/messaging/discussionthread/${threadId}/markread`, { messageId });
    };
}

export default new DiscussionThreadService();
