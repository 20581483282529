import React, { useState } from 'react';
import { IDiscussionThread } from '../@types/discussionthread';
import { getSentAtTitle, getUserDisplayName } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import { Icon } from '@vismaux/react-vud';
import QuickAddRecipients from './QuickAddRecipients';
import { FormProvider, useForm } from 'react-hook-form';
import { MessagingState } from '../context/Context';
import { ActionType } from '../context/ActionTypes';
import classNames from 'classnames';
import { LoadingState } from '../@types/loadingstates';
import DiscussionThreadMenu from './DiscussionThreadMenu';
import { useNavigate, useParams } from 'react-router-dom';
interface Props {
    discussionThread: IDiscussionThread,
}

const DiscussionTitle = ({ discussionThread }: Props) => {
    const { t } = useTranslation();
    const [isAddingRecipients, setIsAddingRecipients] = useState<boolean>(false);
    const { state: { activeDiscussionThread }, dispatch} = MessagingState();
    const methods = useForm({ defaultValues: { recipients: [] } });
    const navigate = useNavigate();
    const { folderParam, pageNumber } = useParams();

    //used to prevent users from adding new users when thread is a reply to sender and shows correct date on title
    const isReplyMessage = activeDiscussionThread?.author?.roleGuid != activeDiscussionThread?.messages[0].author.roleGuid;

    const cancelAddingRecipients = () => {
        setIsAddingRecipients(false);
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>, id: number) => {
        if (ev.key === 'Enter') {
            setActiveDiscussionThread(id);
        }
    };

    const setActiveDiscussionThread = (id: number) => {
        navigate(`/${folderParam}/page/${pageNumber}`);
        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: { thread: null, loadingState: LoadingState.Done }
        });
        document.getElementById(`discussionThread${id}`)?.focus();
    };

    return (
        <div id="discussion-title-wrapper">
            <div className="row flex-nowrap">
                <div className='col-md-1'>
                    <div
                        tabIndex={0}
                        className='btn btn-default icon-button'
                        id='discussion-thread-back-button'
                        aria-label={t('buttons.backToThreadList')}
                        onClick={() => {
                            activeDiscussionThread && setActiveDiscussionThread(activeDiscussionThread.id);
                        }}
                        onKeyDown={(e) => {
                            activeDiscussionThread && handleKeyDown(e,activeDiscussionThread.id);
                        }}>
                        <Icon
                            name='vismaicon vismaicon-dynamic vismaicon-sm vismaicon-arrow-left-circle'
                            size='sm' />
                    </div>
                </div>
                <div className="col-md-10">
                    <span
                        className="discussion-header"
                        tabIndex={-1}>{discussionThread.name}
                    </span>
                </div>
                {activeDiscussionThread && (
                    <DiscussionThreadMenu activeDiscussionThread={activeDiscussionThread} />
                )}
            </div>
            <br />
            <div className="row">
                <div
                    id="discussion-title-author"
                    className="col-md">
                    {t('originalAuthor') + (getUserDisplayName(discussionThread.author))}
                </div>
                <div
                    id="discussion-title-date"
                    className="col-md date">
                    {getSentAtTitle(isReplyMessage && discussionThread.messages.length > 1 ? discussionThread.messages[1].sentAt : discussionThread.messages[0].sentAt)}
                </div>
            </div>
            <div
                className="row"
                id="discussion-recipients-row">
                <div className='col'>
                    <div
                        id="discussion-recipient-text-row"
                        className='col-md'>{t('to')}
                    </div>
                    <div className='recipient-form-container col-md-10'>
                        <FormProvider {...methods}>
                            <QuickAddRecipients
                                discussionThread={discussionThread}
                                setIsAddingRecipients={setIsAddingRecipients}
                                recipients={discussionThread.recipients.filter(user=>user.roleGuid !== discussionThread.author?.roleGuid)}
                                displayLimit={3}
                                hiddenRecipientAmount={discussionThread.hiddenRecipientsCount}
                                placeholder={t('placeholders.recipients')}
                                isAddingRecipients={isAddingRecipients}
                            />
                        </FormProvider>
                        {discussionThread.canUserAddNewRecipients ? (
                            <div id="adding-recipients-wrapper">
                                <button
                                    id='quick-add-recipients-button'
                                    type='button'
                                    className={classNames('btn btn-default icon-button', {'isAddingRecipients': isAddingRecipients})}
                                    disabled={methods.formState.isSubmitting}
                                    aria-disabled={methods.formState.isSubmitting}
                                    aria-label={!isAddingRecipients ? t('buttons.quickAddRecipients') : t('buttons.cancelAddingRecipients')}
                                    onClick={() => {
                                        if (isAddingRecipients) {
                                            cancelAddingRecipients();
                                        } else {
                                            setIsAddingRecipients(true);
                                        }
                                    }}>
                                    {isAddingRecipients ? (
                                        <Icon
                                            name='cancel-circle'
                                            size='sm'
                                        />
                                    ) : (
                                        <Icon
                                            name='add'
                                            size='sm'
                                        />
                                    )}
                                </button>
                            </div>
                        )
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscussionTitle;