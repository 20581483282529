import React, { useEffect } from 'react';
import DiscussionThread from '../DiscussionThread';
import DiscussionThreadReply from '../DiscussionThreadReply';
import DiscussionTitle from '../DiscussionTitle';
import { useTranslation } from 'react-i18next';
import { MessagingState } from '../../context/Context';
import { Spinner } from '@vismaux/react-vud';
import { useNavigation, useParams } from 'react-router-dom';
import DiscussionThreadService from '../../services/DiscussionThreadService';
import { ActionType } from '../../context/ActionTypes';
import { LoadingState } from '../../@types/loadingstates';

const DiscussionThreadContainer = () => {
    const { state: { errorCanceled, activeDiscussionThread }, dispatch } = MessagingState();
    const { t } = useTranslation();
    const { state } = useNavigation();
    const { discussionThreadId } = useParams();

    useEffect(() => {
        const fetchDiscussionThread = async () => {
            if (discussionThreadId) {
                const discussionThreadContent = await DiscussionThreadService.getDiscussionThread(parseInt(discussionThreadId));
                if (discussionThreadContent) {
                    dispatch({
                        type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                        payload: { thread: discussionThreadContent, loadingState: LoadingState.Done } 
                    });
                }
            }
        };
        fetchDiscussionThread();
    }, [discussionThreadId, dispatch]);

    return (
        <>

            {errorCanceled && (
                <div className='center'>
                    <span className='error-text'>{errorCanceled ? t('errors.discussionThreadCanceled') : t('errors.discussionThreadDataLoadingFailed')}</span>
                </div>
            )}
            {activeDiscussionThread && state === 'idle' && (
                <>
                    <DiscussionTitle
                        discussionThread={activeDiscussionThread}/>
                    <hr className='row no-gutters' />
                    <div
                        id="discussionthread-messages-container"
                        className="row wrapper justify-content-start flex-grow-1 no-gutters">
                        <DiscussionThread
                            discussionThread={activeDiscussionThread}/>
                    </div>
                    <hr className='row no-gutters' />
                    <div className='row justify-content-end no-gutters'>
                        <DiscussionThreadReply />
                    </div>
                </>
            )}
            {state === 'loading' && (
                <Spinner/>
            )}
        </>
    );
};

export default DiscussionThreadContainer;